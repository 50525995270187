import { useEffect } from "react";
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectProspectos } from '../../features/prospectosSlice/prospectosSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { addSymbolSource} from "../../services/index.js";

export const ProspectosMap = ({map}) => {
    const prospectos = useSelector(selectProspectos);
    const style = useSelector(selectStyle);

    const options = { 
        source: 'prospectos',
        layerId: 'prospectos',
        iconImage: ["get", "tipoDeSolicitud"],
        size: 0.04,
        overlap: true,
    };

    const handlerCallback = () => {
        map.on("click", "prospectos", (e) => {
            const [prospectos] = map.queryRenderedFeatures(e.point, {layers: ["prospectos"]})
            
            console.log(prospectos.properties);
        })
    };

    useEffect(() => {
        addSymbolSource(map, prospectos, options, true, () => {handlerCallback()});
    }, [prospectos]);

    useEffect(()=> {
        map.once(('style.load'), function () {
            addSymbolSource(map, prospectos, options);
        })
    }, [style])
    return null
}