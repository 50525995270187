import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [] };

const geocercasSlice = createSlice({
  name: "geocercas",
  initialState,
  reducers: {
    setGeocercas: (state, action) => {
        state.data = action.payload;
    },
    setGeocerca: (state, action) => {
      state.data.push(action.payload);
    },
    setUserToGeocerca: (state, action) => {
      const updatedGeocerca = state.data.map(geocerca => geocerca.idGeocerca === action.payload.idGeocerca ? {...geocerca, associatedUsers: [...geocerca.associatedUsers, action.payload]} : geocerca);
      state.data = updatedGeocerca;

    },
    deleteUserToGeocerca: (state, action) => {
      const updatedGeocerca = state.data.map(geocerca => geocerca.idGeocerca === action.payload.idGeocerca ? {...geocerca, associatedUsers: geocerca.associatedUsers.filter(relation => relation.idRelations !== action.payload.idRelations )} : geocerca);
      state.data = updatedGeocerca;
    },
    deleteGeocerca: (state, action) => {
      const filtered = state.data.filter(item => item.idGeocerca !== action.payload);
      state.data = filtered;
    },
  },
});

export const { 
  setGeocercas, 
  setGeocerca, 
  setUserToGeocerca, 
  deleteGeocerca, 
  deleteUserToGeocerca 
} = geocercasSlice.actions;

export const selectGeocercas = (state) => state.geocercas.data;

export default geocercasSlice.reducer;