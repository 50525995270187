import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { useState } from 'react';
import { 
  useDispatch, 
  useSelector 
} from 'react-redux';
import { 
  setElement, 
  selectElement 
} from '../../features/elementSlice/elementSlice.js';
import { setMarker } from '../../features/markersSlice/markersSlice.js';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import * as images from '../../const/images.js';
import { toastOptions } from '../../const/toastOptions.js';
import { api } from '../../const/apiUpload.js';
import './index.css';

const objectToArray = (object) => { 
    const array = Object.keys(object).map(key=> ({key, value: object[key]}))
    return array;
  }

export const SaveElement = () => {
  const dispatch = useDispatch();
  const element = useSelector(selectElement);
  const [processing, setProcessing] = useState(false);
  if (!element) {
    return null;
  };

  const onClose = () => {
    setProcessing(false);
    dispatch(setElement(null));
  };

  const handleSubmit = async () => {
    if (processing) {
      return;
    }
    try {
      setProcessing(true);
      const response = await axios.post(`${api}/create-elementos`, element);
      const { data } = response;
      dispatch(setMarker(data));
      toast.success(`El elemento ${data.name} ha sido creado`, toastOptions);
    } catch (error) {
      toast.error(`${error.response.data.error}`, toastOptions);
    }
    onClose();
  };

  return (
    <ModalBase
      id='Save-element-modal'
      title='Guardar elemento'
      onClose={onClose}
    >
      <div className='modal-content-section'>
        <div className='element-name-container'>
          <p>
            {element.name}
          </p>
        </div>
      </div>
      <div className='modal-content-section'>
        <div  className='element-image'>
          <img src={images[element.img]} alt={element.name}/>
        </div>  
      </div>
      <div className='modal-content-section'>
        {objectToArray(element.atributos).map(atributo => (
          <div className='element-properties-container' key={uuid()}>
            <p className='element-propertie'>{atributo.key}: {atributo.value}</p>
          </div>
        ))} 
      </div>
      <div className='modal-content-section'>
        <Button onClick={handleSubmit} processing={processing} disabled={processing}>
          Guardar elemento
        </Button>
      </div>
    </ModalBase> 
  )
}
