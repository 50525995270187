import { SidebarMenuContainer } from "../sidebar-menu-container";
import { Button } from "../Button";
import { GridContainer } from "../grid";
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectUsers } from "../../features/usersSlice/usersSlice";
import { setUser } from "../../features/userSlice/userSlice.js";
import { setOpen } from "../../features/openModalSlice/openModalSlice";
import { 
    ASSOSIATE_GEOCERCA_TO_USER,
    CREATE_USER,
    DELETE_USER,
    UPDATE_USER
} from "../../const/modalName";
import { ADMINISTRADOR } from "../../const/userRoles";

export const UsersMenu = ({handleActive}) => {
    const users = useSelector(selectUsers);
    const dispatch = useDispatch();
    const handleCreate = () => {
        dispatch(setOpen(CREATE_USER));
    }
    return (
        <SidebarMenuContainer handleActive={handleActive} title='Usuarios'>
            {!!users.length ? 
                users.map((user) => (
                <GridContainer  
                    key={user.idUser}
                    name={user.name}
                >
                    <GridMenu user={user}/>
                </GridContainer>
            ))
            :   <div className='modal-content-section'>
                    <p>
                        No hay ningun usuario registrado
                    </p>
                </div>
            }
            <Button onClick={handleCreate}>
                Agregar usuario
            </Button>
        </SidebarMenuContainer>
    );
};

const GridMenu = ({user}) =>  {
    const administrador = user.role === ADMINISTRADOR;
    const dispatch = useDispatch();
    const handleDisplay = () => {
       dispatch(setUser(user));
       dispatch(setOpen(ASSOSIATE_GEOCERCA_TO_USER))
    };
    const handleDelete = () => {
        dispatch(setUser(user));
        dispatch(setOpen(DELETE_USER));
    }
    const handleEdit = () => {
        dispatch(setUser(user));
        dispatch(setOpen(UPDATE_USER));
    }
    return (
        <div className='grid-menu'>
            <ul>
                {!administrador ? <li onClick={handleDisplay}>Asociar geocerca</li> : null}
                <li onClick={handleEdit}>Editar</li>
                <li onClick={handleDelete}>Eliminar</li>
            </ul>
        </div>
    )
}