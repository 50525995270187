import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const kmlSlice = createSlice({
  name: "kml",
  initialState,
  reducers: {
    setKmlFiles: (state, action) => {
      state.data =  action.payload;
    },
    setKml: (state, action) => {
        state.data.push(action.payload)
    },
    deleteKml: (state, action) => {
      state.data = state.data.map((item) => item.idkmlFiles === action.payload ? {kmlName: item.kmlName, originalName: item.originalName, idkmlFiles: item.idkmlFiles, deleted: true} : item)
    },
    /*setSelect: (state, action) => {
      state.data = state.data.map((item, index) => ({...item, selected: index === action.payload.index ? action.payload.selected : item.selected}))
    }, */
  },
});

export const { setKml, setKmlFiles, deleteKml } = kmlSlice.actions;

export const selectKml = (state) => state.kml.data;
export const selectKmlInfo = (state) => state.kml.data.filter(item => !item.deleted).map((item) => ({kmlName: item.kmlName, originalName: item.originalName, idkmlFiles: item.idkmlFiles}));

export default kmlSlice.reducer;