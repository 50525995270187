import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const elementsSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    setElements: (state, action) => {
        state.data = action.payload;
    },
  },
});

export const { setElements } = elementsSlice.actions;

export const selectElements = (state) => state.elements.data;

export default elementsSlice.reducer;