import { MainPage } from './pages/main-page/index.js';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route,
} from 'react-router-dom';
import * as ROUTES from './const/route.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path={ROUTES.MAIN} element={<MainPage/>}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
