import './index.css';

export class BaseControl {

    constructor(options) {
      this.options = options;
    }
  
    getDefaultPosition() {
      return 'top-right';
    }
    
    onAdd(map) {
      this.map = map;
      // agrega el marker control al panel del mapa
      this.controlContainer = document.createElement('div');
      this.controlContainer.classList.add('maplibregl-ctrl');
      this.controlContainer.classList.add('maplibregl-ctrl-group');
     // this.controlContainer.classList.add('maplibregl-ctrl-group-marker')
      this.styleButton = document.createElement('button');
      this.styleButton.type = 'button';
      this.styleButton.classList.add('maplibregl-ctrl-icon');
      this.styleButton.classList.add('maplibregl-style-icon');
      this.styleButton.style.setProperty('background-image', `url(${this.options.iconImage})`, 'important');
      this.styleButton.addEventListener('click', () => {
        const target = document.getElementById(this.options.targetId);
        target.classList.toggle("closed");
      });
      this.controlContainer.appendChild(this.styleButton);
      return this.controlContainer;
    }
} 