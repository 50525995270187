import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { Delete } from '@mui/icons-material';
import './index.css';

export const DeleteModal = ({onClose, onSubmit, title, message}) => (
        <ModalBase
            id='delete-modal-container'
            title={title}
            onClose={onClose}
        >
            <div className='delete-message-container'>
                <div className='delete-message-icon'>
                    <Delete/>
                </div>
                <div className='delete-message'>
                    <p>
                        {message}
                    </p>
                </div>
            </div>
            <Button 
                className='delete-btn'
                onClick={onSubmit}    
            >
                Eliminar    
            </Button>            
        </ModalBase>
)