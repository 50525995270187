import axios from 'axios';
import { DeleteModal } from '../delete-modal/index.js';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { 
    setUser, 
    selectUser,
} from '../../features/userSlice/userSlice.js';
import { deleteUser } from '../../features/usersSlice/usersSlice.js';
import { toast } from 'react-toastify';
import { toastOptions } from '../../const/toastOptions.js';
import { api } from '../../const/apiUpload.js';
import { useState } from 'react';

export const DeleteUser = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState(false);

    const onClose = () => {
        setProcessing(false);
        dispatch(setUser(null));
    };
    if (!user) {
        return null;
    }

    const handleSubmit = async() => {
        if (processing) {
            return;
        }
        try {
            setProcessing(true);
            await axios.delete(`${api}/delete-user/${user.idUser}`);
            dispatch(deleteUser(user.idUser));
            toast.info(`El usuario ${user.name} ha sido eliminado`, toastOptions);

        } catch (error) {
            toast.error(`${error.response.data.error}`, toastOptions);
        }
        onClose();
    };

    return (
        <DeleteModal
            onClose={onClose}
            onSubmit={handleSubmit}
            title='Eliminar usuario'
            message={`¿Desea eliminar el usuario ${user.name}?`}
        />
    )
}