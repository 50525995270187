import './index.css';

export const ErrorPage = ({message='Not Found', status=400}) => (
    <div className='error-page'>
        <div className='error-page-container'>
            <div  className='error-page-status-container'>
                <p>
                    {status}
                </p>
                
            </div>
            <div  className='error-page-message-container'>
                <p>
                    {message}
                </p>
            </div>
        </div>        
    </div>
)

