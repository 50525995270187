import { GridContainer } from '../grid/index.js';
import { SidebarMenuContainer } from "../sidebar-menu-container";
import { useSelector } from 'react-redux';
import { selectGeocercas } from '../../features/geocercasSlice/geocercasSlice.js';
import { useDispatch } from 'react-redux';
import { setGeocerca } from '../../features/geocercaSlice/geocercaSlice.js';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import {
    DELETE_GEOCERCA,
    ASSOSIATE_USER_TO_GEOCERCA,
} from '../../const/modalName';

export const GeocercasMenu = ({handleActive}) => {
    const geocercas = useSelector(selectGeocercas);
    return (
        <SidebarMenuContainer handleActive={handleActive} title="Geocercas">
            {!!geocercas.length ? 
                geocercas.map((geocerca) => (
                <GridContainer  
                    key={geocerca.idGeocerca}
                    name={geocerca.name}
                    
                >
                    <GridMenu geocerca={geocerca}/>
                </GridContainer>
            ))
            :   <div className='modal-content-section'>
                    <p>
                        No se han creado geocercas
                    </p>   
                </div>
            }
        </SidebarMenuContainer>
    );
};

const GridMenu = ({geocerca}) =>  {
    const dispatch = useDispatch();
    const handleAssosiateUsers = () => {
       dispatch(setGeocerca(geocerca));
       dispatch(setOpen(ASSOSIATE_USER_TO_GEOCERCA));
    };
    const handleDelete = () => {
        dispatch(setGeocerca(geocerca));
        dispatch(setOpen(DELETE_GEOCERCA));
    };
    return (
        <div className='grid-menu'>
            <ul>
                <li onClick={handleAssosiateUsers}>Asociar usuario</li>
                <li onClick={handleDelete}>Eliminar</li>
            </ul>
        </div>
    )
}