import { mapApiKey } from './maptilerApiKey.js';

export const mapStyles = {
    
    mapTilerStreet: {
        url: `https://api.maptiler.com/maps/streets/style.json?key=${mapApiKey}`,
        name: 'MapTiler Street',
    },
    mapTilerBasic: {
        url: `https://api.maptiler.com/maps/basic/style.json?key=${mapApiKey}`,
        name: 'MapTiler basic'
    },
    mapTilerSatelliteHybrid: {
        url: `https://api.maptiler.com/maps/hybrid/style.json?key=${mapApiKey}`,
        name: 'MapTiler Satellite Hybrid',
    },
    mapTilerBright: { 
        url: `https://api.maptiler.com/maps/bright/style.json?key=${mapApiKey}`,
        name: 'MapTiler Bright',
    },
    openStreetMap: {
        url: `https://api.maptiler.com/maps/openstreetmap/style.json?key=${mapApiKey}`,
        name: 'OpenStreetMap',
    },
    
}