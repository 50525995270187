import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useState } from "react";
import './index.css';

export const GridContainer = ({name, children}) => {
    const [display, setDisplay] = useState(false);
    const handleClose = () =>  {
        setDisplay(false);
        document.removeEventListener('click', handleClose);
    }
    
    const handleOpen = (e) => {
        e.stopPropagation();
        setDisplay(true);
        document.addEventListener('click', handleClose);
    }
    return (
        <div className='grid-container'>
            <div className='grid-name'>
                <p>
                    {name}
                </p>
            </div>
            <div className='grid-menu-btn'>
                <IconButton onClick={handleOpen}>
                    <MoreVert/>
                </IconButton>
                {display ? children : null}
            </div>
        </div> 
    )
}