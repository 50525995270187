import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null
};

const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
        state.data = action.payload;
    },
    updateAuthUser: (state, action) => {
      if (action.payload.userId === state.userId) {
        const updated = {...state.data,  ...action.payload}
        state.data = updated;
      }
    }
  },
});

export const { setAuthUser, updateAuthUser } = authUserSlice.actions;

export const selectAuthUser = (state) => state.authUser.data;

export default authUserSlice.reducer;