import axios from 'axios';
import tjson from '@mapbox/togeojson';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { Input } from '../Input/index.js';
import { ErrorUpload } from '../error-upload/index.js';
import { AttachFile } from '@mui/icons-material';
import { api } from '../../const/apiUpload.js';
import { 
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setKml } from '../../features/kmlSlice/kmlSlice.js';
import { setloading } from '../../features/loadingKmlSlice/loadingKmlSlice.js';
import './index.css';

export const FileUpload = () => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(null);
    const [value, setValue] = useState("");

    const onChangeName = (e) => {
        setValue(e.target.value);
    }

    const handleInputClick = () => {
        inputRef.current.click();
    };

    const changeHandler = async (event) => {
        const [ currentFile ] = event.target.files;
        if (!currentFile || !currentFile.name.includes('kml')) {
            return;
        }
        setSelectedFile(currentFile); 
	};

    const onClose = () => {
        setSelectedFile(null);
        setValue('');
        setError(null);
        const target = document.getElementById('file-upload-container');
        target.classList.toggle("closed");
    }

    const handleError = () => {
        setError(null);
    }

    const handleUpload = async () => {
        if (!value || !selectedFile) {
            return;
        }
        onClose();
        dispatch(setloading(true));
        // le da formato al archivo para que el servidor lo procese 
        const file = new FormData();
        file.append('file', selectedFile);
        
        try {
            // mandar archivo al servidor
            const uploadResponse = await axios.post(
                `${api}/upload`, 
                file, 
                { headers: {"x-data-kml-name": value } }
            );
            const { 
                data : {
                    originalname,
                    kmlName,
                    idkmlFiles
                } 
            } = uploadResponse;
            

            if (!originalname) {
                return;
            }
            // utiliza el nombre original del archivo como parametro para leerlo
            const fileName = originalname.replace(".kml", "");
            const fileResponse = await axios.get(`${api}/read/${fileName}`);
            const { data } = fileResponse;
            //transforma el archivo kml en un node object
            const node = new DOMParser().parseFromString(data, 'text/xml');
            //trasnforma el node object del kml en json
            const geoJson = tjson.kml(node);
            dispatch(setKml({kmlName, originalName: fileName, idkmlFiles, ...geoJson}));
        } catch (err) {
            setError(err.response.data.error);
            dispatch(setloading(false));
        }
    }

    return (
        <>
            {error ? 
                <ErrorUpload 
                    message={error}
                    onClick={handleError}
                /> 
            : null }
            <ModalBase
                id='file-upload-container' 
                title='Cargar archivo' 
                onClose={onClose}
                extend='closed'
            >
                <div className='modal-content-section'>
                    <div className='file-input-label'>
                        <p>
                            Seleccionar archivo
                        </p>
                    </div>
                    <div className='file-input-container'>
                        <input 
                            className="file-input"
                            type="file" 
                            onChange={changeHandler}
                            ref={inputRef}
                        />
                        <div 
                            className='file-container'
                            onClick={handleInputClick}
                        >
                            
                            
                            {!selectedFile ?
                                (<>
                                    <AttachFile/>
                                    <p>
                                        Seleccionar archivo
                                    </p>
                                </>)
                                :   (<p>
                                        {selectedFile.name}
                                    </p>)
                            } 
                        </div>
                    </div>
                </div>
                <div className='modal-content-section'>
                    <div className='name-input-label'>
                        <p>
                            Nombre
                        </p>
                    </div>
                    <Input
                        id="add-capa-input"
                        value={value}
                        onChange={onChangeName}
                        placeholder='Agrega nombre al kml'
                    />
                </div>
                <div className='modal-content-section'>
                    <Button onClick={handleUpload}>
                        Guardar
                    </Button>
                </div>
            </ModalBase>
        </>
    )
}