import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import './index.css';

export const SidebarMenuContainer = ({handleActive, title, children}) => {
    const handleClose = () => {
        handleActive((prevState) => false)
    }
    return (
        <div className='menu-container'>
            <div className='back-menu'>
                <div className='back-menu-btn'>
                    <IconButton onClick={handleClose}>
                        <ArrowBack/>
                    </IconButton>
                </div>
                <div className='menu-title'>
                    <h5>
                        {title}
                    </h5>
                </div>
            </div>
            <div className='menu'>
                <div className='menu-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}