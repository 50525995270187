export const cajaNap = `${process.env.PUBLIC_URL}/images/caja-nap.png`;
export const caja = `${process.env.PUBLIC_URL}/images/caja.png`;
export const manga = `${process.env.PUBLIC_URL}/images/manga.png`;
export const poste = `${process.env.PUBLIC_URL}/images/poste.png`;
export const condominio = `${process.env.PUBLIC_URL}/images/condominio.png`;
export const hub = `${process.env.PUBLIC_URL}/images/hub.png`;
export const empresa = `${process.env.PUBLIC_URL}/images/empresa.png`;
export const person = `${process.env.PUBLIC_URL}/images/person.png`;
export const hogar = `${process.env.PUBLIC_URL}/images/hogar.png`;
export const switcherIcon = `${process.env.PUBLIC_URL}/images/map-styles.svg`; 
export const markerIcon = `${process.env.PUBLIC_URL}/images/marker.png`;
export const uploadIcon = `${process.env.PUBLIC_URL}/images/upload.png`;
export const settings = `${process.env.PUBLIC_URL}/images/settings.png`;