import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const markersSlice = createSlice({
  name: "elements",
  initialState,
  reducers: {
    setMarkers: (state, action) => {
      state.data = action.payload;
    },
    setMarker: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setMarkers, setMarker } = markersSlice.actions;

export const selectMarkers = (state) => state.markers.data;

export default markersSlice.reducer;