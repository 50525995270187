import { AssosiateModalItem } from '../assosiate-modal-item/index.js';
import { ModalBase } from '../modal-base/index.js';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectUsers } from '../../features/usersSlice/usersSlice.js';
import { 
    setGeocerca,
    selectGeocerca
} from '../../features/geocercaSlice/geocercaSlice.js';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import { v4 as uuid} from 'uuid';

export const AssosiateUsersModal = () => {
    const users = useSelector(selectUsers);
    const geocerca = useSelector(selectGeocerca);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setGeocerca(null));
        dispatch(setOpen(false));
    }

    if (!geocerca) {
        return null;
    }

    return (
        <ModalBase
            id='assosiate-users-modal'
            title='Asociar usuarios'
            onClose={onClose}
        >
            {!!users.length ? 
                users.map(user => (
                    <AssosiateModalItem 
                        key={uuid()}
                        name={user.name}
                        idUser={user.idUser}
                        idGeocerca={geocerca.idGeocerca}
                        idRelations={user.associatedGeocercas.find(item => item.idGeocerca === geocerca.idGeocerca && item.idUser === user.idUser)?.idRelations}
                        checked={user.associatedGeocercas.map(item=> item.idGeocerca).includes(geocerca.idGeocerca)}
                    />
                ))
            : <p>No hay Usuarios registrados</p>}
        </ModalBase>
    )
}