import './index.css';

export const Input = ({id='', value, name='', type='text', onChange, placeholder='', autoFocus=false, error=null, success=null}) => (
	<input
		id={id}
		value={value}
		name={name}
		type={type}
		onChange={onChange}
		placeholder={placeholder}
        className={`input-base`}
		autoFocus={autoFocus}
		error={error}
		success={success}
		autoComplete='off'
	/>
)