import { useEffect } from "react";
import { selectKml } from '../../features/kmlSlice/kmlSlice.js';
import { 
    selectLoading, 
    setloading
} from '../../features/loadingKmlSlice/loadingKmlSlice.js';
import { selectStyle } from '../../features/mapPreferece/mapPreference.js';
import { setProperties } from '../../features/featurePropertiesSlice/featurePropertiesSlice.js';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

export const KmlMap = ({ map }) => {
    const kmlObjects = useSelector(selectKml);
    const loading = useSelector(selectLoading);
    const style = useSelector(selectStyle);
    const dispatch = useDispatch();
  
    const addKmlSource = (addHandler=false) => {
        if (!kmlObjects.length) { return; }
        // modifica el json obtenido del kml para usar las imagenes como iconos
        const updateGeoJsons = kmlObjects.map((kml) => {
            if (kml.deleted) {
                return kml;
            }
            const features = kml.features.map((item) =>
                item.geometry.type === 'Point' ?
                    {
                        ...item,
                        "properties": {
                            ...item.properties,
                            //si el nombre del feature contiene la palabra nap agregale el icono de caja nap
                            ...(item.properties.name.toLowerCase().includes("nap") ? { "icon": "caja-nap" }
                                //si el nombre del feature contiene la palabra manga agregale el icono de manga
                                : item.properties.name.toLowerCase().includes("manga") ? { "icon": "manga" }
                                    : {})
                        },
                    }
                    : item);
            return {
                ...kml,
                features
            };
        });
        for (let i = 0; i < updateGeoJsons.length; i++) {
            const source = map.getSource(`kml-data-${updateGeoJsons[i].kmlName}`);
            if (source && updateGeoJsons[i].deleted) {
                map.removeLayer(`kml-polygon-${updateGeoJsons[i].kmlName}`);
                map.removeLayer(`kml-point-${updateGeoJsons[i].kmlName}`);
                map.removeLayer(`kml-line-${updateGeoJsons[i].kmlName}`);
                map.removeSource(`kml-data-${updateGeoJsons[i].kmlName}`);
                continue;
            } else if (!source && updateGeoJsons[i].deleted){
                continue;
            }
            if (!source) {
                
                //agrega el ultimo json como source al mapa
                map.addSource(`kml-data-${updateGeoJsons[i].kmlName}`, {
                    type: 'geojson',
                    data: {
                        "type": updateGeoJsons[i].type,
                        "features": updateGeoJsons[i].features,
                    }
                })

                // styles para el archivo kml
                // agrega styles al los poligonos
                map.addLayer({
                    'id': `kml-polygon-${updateGeoJsons[i].kmlName}`,
                    'type': 'fill',
                    'source': `kml-data-${updateGeoJsons[i].kmlName}`,
                    'paint': {
                        "fill-color": ["get", "fill"],
                        "fill-opacity": ["get", "fill-opacity"],
                        "fill-outline-color": ["get", "stroke"],
                    },
                    'filter': ['==', '$type', 'Polygon']
                });

                // agrega styles al los puntos
                map.addLayer({
                    'id': `kml-point-${updateGeoJsons[i].kmlName}`,
                    'type': 'symbol',
                    'source': `kml-data-${updateGeoJsons[i].kmlName}`,
                    'layout': {
                        'text-field': ["get", "name"],
                        'text-anchor': "top",
                        'icon-size': 0.3,
                        'icon-image': ["get", "icon"],
                        'icon-allow-overlap': true, 
                        'text-offset': [0, 2],
                    },
                    'paint': {
                        'text-color': "#050505",

                    },
                    'filter': ['==', '$type', 'Point']
                });
                // agrega styles a las lineas
                map.addLayer({
                    'id': `kml-line-${updateGeoJsons[i].kmlName}`,
                    'type': 'line',
                    'source': `kml-data-${updateGeoJsons[i].kmlName}`,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': ["get", "stroke"],
                        'line-width': ["get", "stroke-width"],
                        'line-opacity': ["get", "stroke-opacity"]
                    },
                    'filter': ['==', '$type', 'LineString']
                });

                if (addHandler) {
                    // selecciona los poligonos dibujados en el mapa 
                    map.on('click', `kml-polygon-${updateGeoJsons[i].kmlName}`, (e) => {
                        // obten las coordenadas y propiedades del poligono seleccionado    
                        const features = map.queryRenderedFeatures(e.point, { layers: [`kml-polygon-${updateGeoJsons[i].kmlName}`] });
                        const otherLayers = map.queryRenderedFeatures(e.point, { layers: ['prospectos']});
                        const selectingElement = map.getSource('select-element')

                        if (!features.length || otherLayers.length || selectingElement) {
                            return;
                        }
                        //si ya hay un poligono seleccionado, deseleccionalo
                        if (map.getSource('selectedFeature')) {
                            const isFeatureSelected = map.queryRenderedFeatures(e.point, { layers: ['selectedFeature'] }).length;
                            map.removeLayer('selectedFeature')
                            map.removeSource('selectedFeature');
                            // si se vuelve a seleccionar el mismo poligono, deseleccionalo                
                            if (!!isFeatureSelected) {
                            //cierra el panel de propiedades
                                dispatch(setProperties(null));
                                return;
                            }
                        }
                        const feature = features[0];
                        //agrega las coordenadas y propiedades del poligono a un layer 'selectedFeature', que marcara el poligono en el mapa
                        map.addSource('selectedFeature', {
                            "type": "geojson",
                            "data": feature.toJSON()
                        });
                        //agrega stylos al poligono seleccionado
                        map.addLayer({
                            "id": "selectedFeature",
                            "type": "fill",
                            "source": "selectedFeature",
                            "paint": {
                                "fill-color": feature.properties.fill,
                                "fill-opacity": 0.6,
                                "fill-outline-color": '#343434',
                            }
                        });

                        dispatch(setProperties(feature.properties))
                    });

                    map.on('click', `kml-point-${updateGeoJsons[i].kmlName}`, (e) => {

                        const features = map.queryRenderedFeatures(e.point, { layers: [`kml-point-${updateGeoJsons[i].kmlName}`] });

                        if (!features.length) {
                            return;
                        }

                        if (map.getLayer('selectedFeature')) {

                            const isFeatureSelected = map.queryRenderedFeatures(e.point, { layers: ['selectedFeature'] }).length;
                            map.removeLayer('selectedFeature')
                            map.removeSource('selectedFeature');
                            if (!!isFeatureSelected) {
                                dispatch(setProperties(null));
                                return;
                            }
                        }
                        const feature = features[0];
                        map.addSource('selectedFeature', {
                            "type": "geojson",
                            "data": feature.toJSON()
                        });
                        map.addLayer({
                            "id": "selectedFeature",
                            "type": "circle",
                            'paint': {
                                "circle-color": 'transparent',
                                "circle-radius": 24,
                                "circle-stroke-color": '#FFF',
                                "circle-stroke-opacity": 0.8,
                                "circle-stroke-width": 4,

                            },
                            "source": "selectedFeature",
                        });

                        dispatch(setProperties(feature.properties))
                    });

                    map.on('click', `kml-line-${updateGeoJsons[i].kmlName}`, (e) => {

                        const features = map.queryRenderedFeatures(e.point, { layers: [`kml-line-${updateGeoJsons[i].kmlName}`] });

                        if (!features.length) {
                            return;
                        }

                        if (map.getLayer('selectedFeature')) {

                            const isFeatureSelected = map.queryRenderedFeatures(e.point, { layers: ['selectedFeature'] }).length;
                            map.removeLayer('selectedFeature')
                            map.removeSource('selectedFeature');
                            if (!!isFeatureSelected) {
                                dispatch(setProperties(null));
                                return;
                            }
                        }
                        const feature = features[0];
                        map.addSource('selectedFeature', {
                            "type": "geojson",
                            "data": feature.toJSON()
                        });
                        map.addLayer({
                            "id": "selectedFeature",
                            "type": "line",
                            'layout': {
                                'line-join': 'round',
                                'line-cap': 'round'
                            },
                            'paint': {
                                'line-color': feature.properties.stroke,
                                'line-width': feature.properties['stroke-width'] * 2,
                                'line-opacity': 0.4,
                            },
                            "source": "selectedFeature",
                        });

                        dispatch(setProperties(feature.properties))
                    });
                }
                

            } else {
                source.setData({
                    "type": updateGeoJsons[i].type,
                    "features": updateGeoJsons[i].features,
                })
            }
        }
        if (loading) {
            const {
                coordinates,
                type,
            } = updateGeoJsons[updateGeoJsons.length - 1].features[0].geometry;
            let zoomCenter = type === 'Point' ? [coordinates[0], coordinates[1]] : [coordinates[0][0][0], coordinates[0][0][1]];
            setTimeout(() => {
                dispatch(setloading(false));
                map.setCenter(zoomCenter);
                map.setZoom(10);
            }, 500);
        }
    }

    useEffect(() => {
        if (!map) { return; }
        addKmlSource(true);
    }, [kmlObjects])

    useEffect(()=> {
        map.once(('style.load'), function () {
            addKmlSource();
        })
    }, [style])
    return null
}