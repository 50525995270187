export const toGeojson = (geocercas) => {
    if (!geocercas.length) {
        return [];
    }
    const features = geocercas.map((geocerca) => {
        const {
            coordinates,
            type,
        } = geocerca;
        return {
            geometry : {
                coordinates,
                type,
            },
            type: "Feature",
            properties: { ...geocerca, coordinates }
        }
    })
    return {
        features,
        type: 'FeatureCollection',
    };
}