import { ModalBase } from '../modal-base/index.js';
import { Input } from '../Input/index.js';
import { Button } from '../Button/index.js';
import { 
    Select, 
    MenuItem 
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setElement } from '../../features/elementSlice/elementSlice.js';
import { selectElements } from '../../features/elementsSlice/elementsSlice.js';
import * as images from '../../const/images.js';
import { v4 as uuid} from 'uuid';

import './index.css';

const setInitialState = (array) => {
    const object = {};
    for (let i = 0; i < array.length; i++) {
        if (array[i].type === "select") {
            object[array[i].name] = array[i].options[0];
            continue;
        }
        object[array[i].name] = "";
        
    }
    return object;
}

export const AddElement = ({map}) => {
    const [selected, setSelected] = useState(0);
    const elements = useSelector(selectElements);
    const [values, setValues] = useState(setInitialState(elements[selected].atributos));
    const disabled = !Object.values(values).every(value => !!value);
    
    const dispatch = useDispatch();

    const handleSelect = (index) => {
        setSelected(index)
        setValues(setInitialState(elements[index].atributos));
    }


    const onClose = () => {
        const target = document.getElementById('add-element-modal');
        target.classList.toggle("closed");
        setSelected(0);
        setValues(setInitialState(elements[0].atributos))
    }

    const onChangeValues = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        setValues(prevState => ({...prevState, [name]: value}))
    }

    const handleClick = (e) => {
        e.stopPropagation();
        const { lng, lat } = map.getCenter();    
        const geojson = {
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [lng, lat]
                    }
                }
            ]
        };

        map.addSource('select-element', {
            type: 'geojson',
            data: geojson,
        })
            
        // styles para el archivo kml
        // agrega styles al los poligonos
        map.addLayer({
            'id': 'select-element',
            'source': 'select-element',
            'type': 'symbol',
            'layout': {
                'icon-image': elements[selected].mapImg,
                'icon-size': elements[selected].size,
                'icon-allow-overlap': true, 
            },
        });
        const canvas = map.getCanvasContainer();
        canvas.style.cursor = 'crosshair';

        const onMove = (e) => {
            const coords = e.lngLat;
    
            // Update the Point feature in `geojson` coordinates
            // and call setData to the source layer `point` on it.
            geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
            map.getSource('select-element').setData(geojson);
        }

        const onStop = () => {
            const currentelement = {
                ...elements[selected], 
                atributos: values,
                lng: geojson.features[0].geometry.coordinates[0],
                lat: geojson.features[0].geometry.coordinates[1],
            }            
            map.off('mousemove', onMove);
            map.removeLayer('select-element');
            map.removeSource('select-element');
            canvas.style.cursor = '';
            dispatch(setElement(currentelement));
        }

        map.on('mousemove', onMove);
        map.once('click', onStop);
        onClose()
    }
    return (
        <ModalBase
            id='add-element-modal'
            title='Seleccionar elemento'
            onClose={onClose}
            extend='closed'
        >   
            <div className='elements-container'>
                {elements.length ?
                    elements.map((element, index) => (
                        <div 
                            className={`select-element ${index === selected ? 'selected-element' : ''}`}
                            onClick={() => handleSelect(index)}
                            key={uuid()}
                        >
                            <div className='element-image'>
                                <img src={images[element.img]} alt='element'/>
                            </div>
                        </div>
                    ))
                    : null}
            </div>
            <div className='property-input'>
                {elements.length ?
                    elements[selected].atributos.map((atributo) => (
                        <div className='modal-content-section' key={`key-${atributo.name}`}>
                            <div className='name-input-label'>
                                <p>
                                    {atributo.name}
                                </p>
                            </div>
                            {atributo.type !== "select" ? 
                                <Input
                                    onChange={onChangeValues}
                                    value={values[atributo.name] || ''}
                                    id={`input-${atributo.name}`}
                                    name={atributo.name}
                                    type={atributo.type}
                                    placeholder={`Agregar ${atributo.name}`}
                                /> :
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    value={values[atributo.name] || atributo.options[0]}
                                    onChange={onChangeValues}
                                    name={atributo.name}
                                >
                                    {atributo.options.map(option => (
                                        <MenuItem value={option} key={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            }
                            
                        </div>
                    ))
                : null}
            </div>
            <div className='modal-content-section'>
                <Button onClick={handleClick} disabled={disabled}>
                    Seleccionar posición en el mapa
                </Button>
            </div>
        </ModalBase>
    )
}