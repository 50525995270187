import axios from 'axios';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { 
    setGeocercaToUser, 
    deleteGeocercaToUser 
} from '../../features/usersSlice/usersSlice.js';
import { 
    setUserToGeocerca, 
    deleteUserToGeocerca 
} from '../../features/geocercasSlice/geocercasSlice.js';
import { api } from '../../const/apiUpload.js';
import './index.css';

export const AssosiateModalItem = ({name, idUser, idGeocerca, checked, idRelations}) => {
    const dispatch = useDispatch();
    const onChange = async() => {
        try {
            if (checked) {
                await axios.delete(`${api}/delete-relation/tmusertogeocerca/${idRelations}`);
                dispatch(deleteUserToGeocerca({idRelations, idGeocerca}));
                dispatch(deleteGeocercaToUser({idRelations, idUser}));
            } else {
                const response = await axios.post(`${api}/create-relation/tmusertogeocerca`, { idUser, idGeocerca});
                const { data } = response;
                dispatch(setUserToGeocerca(data));
                dispatch(setGeocercaToUser(data));
            }
            
        } catch (error) {
            console.error(error)
        }
    }
    
    return (
        <div className='modal-content-section'>
            <div className='users-item-container'>
                <Checkbox 
                    onChange={onChange}
                    checked={checked}
                />
                <p>
                    {name}
                </p>
            </div>
        </div>
    )
}