import axios from 'axios';
import { ModalBase } from '../modal-base/index.js';
import { Button } from '../Button/index.js';
import { Input } from '../Input/index.js';
import { 
    Select, 
    MenuItem 
} from '@mui/material';
import { 
    useDispatch 
} from 'react-redux';
import { useState } from 'react';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import { 
    setUser, 
    selectUser,
} from '../../features/userSlice/userSlice.js';
import { updateUser } from '../../features/usersSlice/usersSlice.js';
import { updateAuthUser } from '../../features/authUserSlice/authUserSlice.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from '../../const/apiUpload.js';
import { toastOptions } from '../../const/toastOptions.js';
import * as ROLES from '../../const/userRoles.js';
import './index.css';

export const UpdateUserModal = () => {
    const user = useSelector(selectUser);     
    const initialState = {
        idUser: user?.idUser,
        name: user?.name,
        phonenumber: user?.phonenumber,
        email: user?.email,
        password: user?.password,
        role: user?.role,
    };
    const [values, setValues] = useState(initialState)
    const [processing, setProcessing] = useState(false);
    const disabled = !Object.values(values).every(value => !!value);
    
    const dispatch = useDispatch();
    
    if (!user) {
        return null;
    }
    const onClose = () => {
        setValues(initialState);
        setProcessing(false);
        dispatch(setUser(null));
        dispatch(setOpen(false));
    }

    const onChange = (e) => {
        const { 
            target: {  
                name, 
                value 
            } 
        } = e;
        
        setValues(prevState => ({...prevState, [name]: value}))
    }

    const handleSubmit = async () => {
        if (disabled || processing) {
            return;
        }
        setProcessing(true);
        try {
            const response = await axios.put(`${api}/update-user/${user.idUser}`, values);
            dispatch(updateUser(values));
            dispatch(updateAuthUser(values));
            toast.info(`Los datos de ${user.name} fueron editados`, toastOptions);
        } catch (error) {
            toast.error(`${error.response.data.error}`, toastOptions);
        }
        onClose()
    }

   
    return (
        <ModalBase
            id='update-user-modal'
            title='Editar usuario'
            onClose={onClose}
        >
            <div className='modal-content'>
                <div className='modal-content-section'>
                    <div className='input-label'>
                        <p>
                            Nombre
                        </p>
                    </div>
                    <Input
                        onChange={onChange}
                        value={values.name}
                        id='input-name'
                        name='name'
                        placeholder='Nombre'
                    />
                </div>
                <div className='modal-content-section'>
                    <div className='input-label'>
                        <p>
                            Telefono
                        </p>
                    </div>
                    <Input
                        onChange={onChange}
                        value={values.phonenumber}
                        id='input-phonenumber'
                        name='phonenumber'
                        placeholder='Telefono'
                    />
                </div>
                <div className='modal-content-section'>
                    <div className='input-label'>
                        <p>
                            Correo
                        </p>
                    </div>
                    <Input
                        onChange={onChange}
                        value={values.email}
                        id='input-email'
                        name='email'
                        placeholder='correo'
                        type='email'
                    />
                </div>
                <div className='modal-content-section'>
                    <div className='input-label'>
                        <p>
                            Contraseña
                        </p>
                    </div>
                    <Input
                        onChange={onChange}
                        value={values.password}
                        type='password'
                        id='input-password'
                        name='password'
                        placeholder='contraseña'
                    />
                </div>
                <div className='modal-content-section'>
                    <div className='input-label'>
                        <p>
                            Role
                        </p>
                    </div>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={values.role}
                        onChange={onChange}
                        name='role'
                    >
                        <MenuItem value={ROLES.ADMINISTRADOR}>Administrador</MenuItem>
                        <MenuItem value={ROLES.USUARIO}>Usuario</MenuItem>
                    </Select>
                </div>
                <div className='modal-content-section'>
                    <Button 
                        disabled={disabled || processing}
                        processing={processing}
                        onClick={handleSubmit}    
                    >
                        Editar
                    </Button>
                </div>
            </div>
        </ModalBase>
    )
}

/*

*/
