import { 
    switcherIcon,
    markerIcon,
    uploadIcon, 
    settings,
    person
} from './images.js';

export const switcherOptions = {
    iconImage: switcherIcon,
    targetId: 'map-style-container',
}

export const markerOptions = {
    iconImage: markerIcon,
    targetId: 'add-element-modal',
}

export const uploadOptions = {
    iconImage: uploadIcon,
    targetId: 'file-upload-container',
}

export const settingsOptions = {
    iconImage: settings,
    targetId: 'settings-main-container',
}

export const clientsOptions = {
    iconImage: person,
    targetId: 'add-clients-container',
}