import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const geocercaSlice = createSlice({
  name: "geocerca",
  initialState,
  reducers: {
    setGeocerca: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setGeocerca } = geocercaSlice.actions;

export const selectGeocerca = (state) => state.geocerca.data;

export default geocercaSlice.reducer;