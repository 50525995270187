import maplibregl from 'maplibre-gl';
import { BaseControl } from '../../controls/base-control/index.js';
import { useEffect } from 'react';
import { useVerifyRole } from '../../hooks/index.js'; 
import * as options from '../../const/controlOptions';
import { ADMINISTRADOR } from '../../const/userRoles.js';

export const Controls = ({map}) => {
    const administrador = useVerifyRole(ADMINISTRADOR);
    const marker = new BaseControl(options.markerOptions);
    const upload = new BaseControl(options.uploadOptions);
    const switcher = new BaseControl(options.switcherOptions);
    const clients = new BaseControl(options.clientsOptions);
    
    useEffect(() => {
        // agregrar controles
        map.addControl(new maplibregl.NavigationControl(
            { showCompass: false }
        ), 'top-right');
        map.addControl(new maplibregl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
        }));

        map.addControl(switcher);
        map.addControl(clients);
        
        if (administrador) {
            map.addControl(marker);
            map.addControl(upload);
        }
    }, [])

    return null;
} 