import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [] };

const prospectosSlice = createSlice({
  name: "prospectos",
  initialState,
  reducers: {
    setProspectos: (state, action) => {
        state.data = action.payload;
    },
    setProspecto: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setProspectos, setProspecto } = prospectosSlice.actions;

export const selectProspectos = (state) => state.prospectos.data;

export default prospectosSlice.reducer;