import { 
    Button,
}  from '@mui/material';
import { Apps } from '@mui/icons-material';
import './index.css';

export const SidebarToggle = ({onClick}) => {
    return (
        <Button 
            variant="outlined" 
            className='sidebar-toggle-btn' 
            startIcon={<Apps/>}
            onClick={onClick}
        >
            menu
        </Button>
    )
}