import { 
    cajaNap, 
    manga,
    caja,
    condominio,
    empresa,
    hogar,
    poste,
    hub,
} from './images.js';

export const mapImages = [
    { 
        img: cajaNap, 
        name: 'caja-nap' 
    }, 
    { 
        img: manga, 
        name: 'manga' 
    }, 
    { 
        img: empresa, 
        name: 'empresa' 
    }, 
    { 
        img: hogar, 
        name: "hogar" 
    },
    {
        img: condominio,
        name: "condominio"
    },
    {
        img: poste, 
        name: "poste" 
    },
    {
        img: caja,
        name: "caja"
    },
    {
        img: hub,
        name: "hub",
    }

]