import { Spinner } from '../spinner';
import './index.css';

export const Button = ({type='button', className='', disabled=false, processing=false, onClick, children}) => {
    const createRipple = (event) => {
        const thisButton = event.target;
        const rect = thisButton.getBoundingClientRect();
        const circle = document.createElement('span');
        const diameter = Math.max(thisButton.clientWidth, thisButton.clientHeight); 
        const radius = diameter / 2;
        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - rect.left - radius}px`;
        circle.style.top = `${event.clientY - rect.top - radius}px`;
        circle.classList.add('ripple');
        if (className.includes('bg-white')) {
            circle.style.backgroundColor = 'rgba(0, 105, 217, 0.6)';
        }
        const ripple = thisButton.getElementsByClassName('ripple')[0];
        if (ripple) {
            ripple.remove();
        }
        thisButton.appendChild(circle);
    };
    return (
        <button
            type={type}
            className={`button-base ${className}`}
            disabled={disabled}
            onClick={(e) => {
                if (onClick) {onClick(e)}
                createRipple(e)
                }}
        >
            {processing ?
               <Spinner/> 
            : children}
        </button>
    )
}
