import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './index.css';

export const ModalBase = ({id='', title, extend='', onClose, children}) => (
    <div id={id} className={`modal-container display-animation ${extend}`}>
        <div className='modal'>
            <div className='modal-header'>
                <div className='modal-header-title'>
                    <p>
                        {title}
                    </p>
                </div>
                <div className='modal-header-close'>
                    <IconButton onClick={onClose}>
                        <Close/>
                    </IconButton>
                </div>
            </div>
            <div className='modal-content-container'>
                {children}
            </div>
        </div>
    </div>
)