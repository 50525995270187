import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
        state.allUsers = action.payload;
    },
    setGeocercaToUser: (state, action) => {
      const updatedUsers = state.allUsers.map(user => user.idUser === action.payload.idUser ? {...user, associatedGeocercas: [...user.associatedGeocercas, action.payload]} : user);
      state.allUsers = updatedUsers;

    },
    addUser: (state, action) => {
      state.allUsers.push(action.payload);
    },
    updateUser: (state, action) => {
      const updatedUsers = state.allUsers.map(user => user.idUser === action.payload.idUser ? {...user, ...action.payload} : user);
      state.allUsers = updatedUsers;
    },
    deleteGeocercaToUser: (state, action) => {
      const updatedUsers = state.allUsers.map(user => user.idUser === action.payload.idUser ? {...user, associatedGeocercas: user.associatedGeocercas.filter(relation => relation.idRelations !== action.payload.idRelations)} : user);
      state.allUsers = updatedUsers;
    },
    deleteUser: (state, action) => {
      const filtered = state.allUsers.filter(item => item.idUser !== action.payload);
      state.allUsers = filtered;
    },
  },
});

export const { 
  setUsers, 
  setGeocercaToUser, 
  addUser, 
  updateUser,
  deleteUser,
  deleteGeocercaToUser
} = usersSlice.actions;

export const selectUsers = (state) => state.users.allUsers;

export default usersSlice.reducer;