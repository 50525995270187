import { AssosiateModalItem } from '../assosiate-modal-item/index.js';
import { ModalBase } from '../modal-base/index.js';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import { selectGeocercas} from '../../features/geocercasSlice/geocercasSlice.js';
import { setOpen } from '../../features/openModalSlice/openModalSlice.js';
import { 
    setUser,
    selectUser
} from '../../features/userSlice/userSlice.js';
import { v4 as uuid} from 'uuid';

export const AssosiateGeocercasModal = () => {
    const geocercas = useSelector(selectGeocercas);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setUser(null));
        dispatch(setOpen(false));
    }
    if (!user) {
        return null;
    }
    return (
        <ModalBase
            id='assosiate-geocercas-modal'
            title='Asociar geocercas'
            onClose={onClose}
        >
            {!!geocercas.length ? 
                geocercas.map(geocerca => (
                    <AssosiateModalItem 
                        key={uuid()}
                        name={geocerca.name}
                        idUser={user.idUser}
                        idGeocerca={geocerca.idGeocerca}
                        idRelations={geocerca.associatedUsers.find(item => item.idGeocerca === geocerca.idGeocerca && item.idUser === user.idUser)?.idRelations}
                        checked={geocerca.associatedUsers.map(item=> item.idUser).includes(user.idUser)}
                    />
                ))

            : <p>No hay Geocercas creadas</p>}
        </ModalBase>
    )
}