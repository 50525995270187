import { 
    AccountCircle,
    Map,
    Terrain,
} from '@mui/icons-material';

export const cards = [
    { title: 'Usuarios', Icon: AccountCircle },
    { title: 'Geocercas', Icon: Map },
    { title: 'Kml', Icon: Terrain },
]