import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal: false,
};

const openModalSlice = createSlice({
  name: "openModal",
  initialState,
  reducers: {
    setOpen: (state, action) => {
        state.modal = action.payload;
    },
  },
});

export const { setOpen } = openModalSlice.actions;

export const selectOpen = (state) => state.openModal.modal;

export default openModalSlice.reducer;