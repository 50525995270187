import { ModalBase } from '../modal-base/index.js';
import { 
    Radio, 
    RadioGroup
} from '@mui/material';
import { 
    setStyle, 
    selectStyle
} from '../../features/mapPreferece/mapPreference.js';
import { 
    useDispatch, 
    useSelector 
} from 'react-redux';
import { v4 as uuid} from 'uuid';
import { mapStyles } from '../../const/mapStyles.js';
import './index.css';

export const MapStyles = ({map}) => {
    const currentStyle = useSelector(selectStyle);
    const dispatch = useDispatch();
    const styles = Object.values(mapStyles);

    const onClose = () => {
        const target = document.getElementById('map-style-container');
        target.classList.toggle("closed");
    };

    const handleSelect = (style) => {
        if (style.name !== currentStyle.name) {
            map.setStyle(style.url, { diff: false });
            dispatch(setStyle(style));
            onClose();
        }
    };
    return (
        <ModalBase
            id='map-style-container' 
            title='Seleccionar mapa' 
            onClose={onClose}
            extend='closed map-style-modal'
        >
            <div className='select-style-container'>

                {styles.map((style)=> (
                    <div 
                        className='style-item'
                        key={uuid()}
                        onClick={() => handleSelect(style)}    
                    >
                        <Radio
                            checked={style.name === currentStyle.name}
                        />
                        <div className='style-name-container'>
                            <p>
                                {style.name}
                            </p>
                        </div>
                    </div>
                ))
                }

            </div>
        </ModalBase>
    )
}